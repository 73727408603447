import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import ISRSeo from "../components/ISRSeo/ISRSeo";
import Spacer from "../components/Spacer/Spacer";
import Footer from "../components/Footer/Footer";

const NotFoundPage = () => {
  return (
    <Layout>
      <Header />
      <ISRSeo title="ISR - 404" />
      <div className="container">
        <Spacer size={150} mobileSize={80} />
        <h1 className="text-center">
          Sorry!
          <br />
          Page not found
        </h1>
        <Spacer size={150} mobileSize={80} />
      </div>
      <Footer />
    </Layout>
  );
};

export default NotFoundPage;
