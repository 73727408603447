import React from "react";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { Helmet } from "react-helmet";

const ISRSeo = ({ data, title, customMeta }) => {
  return (
    <>
      {title ? <Seo post={data} title={title} /> : <Seo post={data} />}
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
      >
        {customMeta && data?.metaDesc && (
          <meta name="description" content={data.metaDesc} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </>
  );
};

export default ISRSeo;
